import VerticalLine_VerticalSolidLineComponent from '@wix/thunderbolt-elements/src/components/VerticalLine/viewer/skinComps/BaseVerticalLine/VerticalSolidLine.skin';


const VerticalLine_VerticalSolidLine = {
  component: VerticalLine_VerticalSolidLineComponent
};


export const components = {
  ['VerticalLine_VerticalSolidLine']: VerticalLine_VerticalSolidLine
};

